/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
require('../plugins');
window.toastr = require('toastr');
window.intlTelInput = require('intl-tel-input');

// Custom CSS Code
require('./scripts');
